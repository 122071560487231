<!-- 首页-待办事项 -->
<template>
    <hm-card
        :height="597"
        class="home-pending"
    >
        <div class="pending-top">
            <hm-title title="待办事项"></hm-title>
        </div>
        <div class="title-right" @click="handleClickMore">
            <span>更多</span>
            <i class="el-icon-arrow-right" />
        </div>
        <div class="pending-wrap">
            <div class="pending-button">
                <el-badge
                    :value="total"
                    :hidden="total <= 0"
                >
                    <hm-button
                        @click="clickButton('1')"
                        :type="
                            form.queryState === '1' ? 'activeMessage' : 'newDefault'
                        "
                    >
                        待处理
                    </hm-button>
                </el-badge>
                <hm-button
                    @click="clickButton('2')"
                    :type="form.queryState === '2' ? 'active' : 'newDefault'"
                    :style="{
                        'margin-left': total < 10 ? '10px' : '18px',
                    }"
                >
                    已处理
                </hm-button>
            </div>
            <el-scrollbar
                v-loading="loading"
                style="height: 527px; background-color: #fff;"
                class="pending-list-wrap"
                wrap-style="overflow-x:hidden"
            >
                <div
                    v-if="list.length > 0"
                    class="pending-list"
                >
                    <div
                        v-for="(item, index) in list"
                        :key="index"
                        class="pending-item"
                        @click="handleClick(item)"
                    >
                        <div class="pending-tag">OA</div>
                        <div class="pending-title ellipsis">
                            {{ item.dataTitle }}
                        </div>
                        <div class="pending-apply-name ellipsis">
                            {{ item.applyName }}
                        </div>
                        <div class="pending-create-time">
                            {{ item.createTime.substring(0, 16) }}
                        </div>
                    </div>
                </div>
                <div
                    v-else-if="!loading"
                    class="pending-empty"
                >
                    <img
                        src="@/assets/images/home/pending-empty.png"
                        alt=""
                        class="pending-empty__img"
                    />
                    <div class="pending-empty__text">暂无待办事项</div>
                </div>
            </el-scrollbar>
        </div>
    </hm-card>
</template>

<script>
import HmCard from "./Sub/hm-card.vue";
import HmTitle from "./Sub/hm-title.vue";
import HmMore from "./Sub/hm-more.vue";
import HmButton from "./Sub/hm-button.vue";
import {mapMutations} from "vuex";

export default {
    name: "HomePending",
    components: {
        HmButton,
        HmCard,
        HmTitle,
        HmMore,
    },
    data() {
        return {
            loading: true,
            form: {
                pageNum: 1,
                pageRow: 20,
                schoolId: this.$store.state.schoolId,
                queryState: "1",
            },
            total: 0,
            list: [
            ],
        };
    },
    created() {
        this.initData();
    },
    methods: {
        ...mapMutations(["setOAQueryData"]),
        initData() {
            this.getList();
        },
        getList() {
            this.loading = true;
            this._fet("/oa/schoolProcessApply/list", this.form)
                .then((res) => {
                    if (res.data.code === "200") {
                        this.list = res.data.data.list;
                        if (this.form.queryState === "1") {
                            this.total = res.data.data.totalCount;
                        }
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        handleClickMore() {
            this.$router.push({
                name: "DealtList",
            });
        },
        clickButton(e) {
            this.form.queryState = e;
            this.getList();
        },
        handleClick(data) {
            this.setOAQueryData({
                id: data.id, // 申请记录id
                dataTitle: data.dataTitle, // 申请title
                backData: {
                    name: this.$route.name,
                    query: {
                        activeName: this.form.queryState,
                    },
                },
            });
            this.$router.push({
                name: "CoworkingCampusOAOAApprovalApplyDetail",
            });
        },
    },
};
</script>

<style scoped lang="scss">
.home-pending {
    background: #f1f1f1;
    // border-radius: 6px 6px 0px 0px;
    padding: 14px 0 0;

    .pending-top {
        padding: 0 16px;
    }
}

.title-right {
    cursor: pointer;
    position: absolute;
    top: 8px;
    right: 11px;

    span {
        color: #333333;
        font-size: 14px;
    }

    .el-icon-arrow-right {
        color: #A4A4A4;
        font-size: 14px;
        margin-left: 2px;
    }
}
.pending-wrap {
    margin-top: 14px;
    .pending-button{
        padding: 0 16px;
    }
}
.pending-list-wrap{
    background: url(~@/assets/images/home/waitIcon.png) no-repeat #ffffff right 20px bottom 14px;
    background-size: 426px 46px;
}
.pending-list {
    // margin-top: 9px;
    background-color: #ffffff;
    padding: 8px 4px;
    .pending-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        line-height: 17px;
        // margin: 0 -12px;
        padding: 8px 12px;
        cursor: pointer;

        &:hover {
            transition: all 0.2s ease;
            background-color: #f0f0f0;
        }

        .pending-tag {
            text-align: center;
            font-size: 12px;
            width: 31px;
            height: 17px;
            line-height: 17px;
            background: #f06f4c;
            color: #ffffff;
            border-radius: 2px;
            margin-right: 5px;
        }

        .pending-title {
            font-size: 14px;
            color: #333333;
            width: 196px;
            margin-right: 31px;
        }

        .pending-apply-name,
        .pending-create-time {
            font-size: 12px;
            color: #999999;
        }

        .pending-apply-name {
            flex: 1;
            margin-right: 20px;
        }
    }
}

.pending-empty {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 480px;
    justify-content: center;

    &__img {
        width: 123px;
        height: 114px;
    }

    &__text {
        line-height: 15px;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
    }
}

.el-badge {
    vertical-align: unset;

    ::v-deep &__content {
        background-color: #f84141;
        border: 0;
    }
}
</style>
