<!-- 首页-校历 -->
<template>
    <hm-card
        v-loading="loading"
        :height="446"
        class="home-schoolCalendar"
    >
        <hm-title title="校历">
            <template #content>
                <div class="title-date">
                    <i class="el-icon-arrow-left arrow-icon" @click="lastMonthHandlerClick"></i>
                    <span class="date">{{ timeGroup.time }}</span>
                    <i class="el-icon-arrow-right arrow-icon" @click="nextMonthHandlerClick"></i>
                    <span class="clarification" @click="todayHandlerClick">今日</span>
                </div>
            </template>
        </hm-title>
        <div v-has-permi="['schoolCalendarManagement:calendar']" class="title-right" @click="handleAllClick">
            <span>全部</span>
            <i class="el-icon-arrow-right" />
        </div>
        <div class="home-calendar">
            <schoolCalendar
                v-model="selectedDate"
                :weekDays="WEEK_DAYS"
                :chunkedDays="chunkedDays"
                :isExpanded="isExpanded"
                :calendar-event="calendarEvent"
            >
            </schoolCalendar>
            <div class="segmentation" @click="toggleExpand">
                <div class="line"></div>
                <i class="caret-icon" :class="isExpanded ? 'el-icon-caret-bottom' : 'el-icon-caret-top'"></i>
            </div>
            <div v-if="calendarEvent[selectedDate] && calendarEvent[selectedDate].list && calendarEvent[selectedDate].list.length > 0" class="event-wrap">
                <div
                    v-for="item in calendarEvent[selectedDate].list"
                    :key="item.id"
                    class="event-title"
                >
                    {{ item.eventTitle }}
                </div>
            </div>
            <div v-else class="empty-tips">
                暂无校历~
            </div>
        </div>
    </hm-card>
</template>

<script>
import {mapState} from "vuex";
import HmCard from "./Sub/hm-card.vue";
import HmTitle from "./Sub/hm-title.vue";
import SchoolCalendar from "./schoolCalendar/index.vue";
import TextTooltip from "@/components/scrollWrapper/SManagerCheckTeacher/Sub/textTooltip.vue";
import {
    getFirstWeekDay,
    getMonthDayCount,
    getLastMonthRestDays,
    getNextMonthRestDays,
    getDateInfo,
    getFormatDate,
    getLastYearMonth,
    getNextYearMonth,
    getFormatDay,
    getMacFormatDate,
} from '@/libs/Calendar/utils.js';
import {
    debounce,
} from "common-local";
import { parseTime } from "@/libs/utils";

export default {
    name: "HomeNoticeCustom",
    components: {
        TextTooltip,
        HmTitle,
        HmCard,
        SchoolCalendar,
    },
    data() {
        return {
            isExpanded: false,
            loading: false,
            year: undefined,
            month: undefined,
            day: undefined,
            WEEK_DAYS: ['日', '一', '二', '三', '四', '五', '六'],
            query: {
                schoolId: '',
                startTime: '',
                endTime: '',
            },
            days: [],
            mergedDays: [],
            chunkedDays: [],
            selectedDate: '',
            calendarEvent: {},
        };
    },
    computed: {
        ...mapState({
            schoolId: (state) => state.schoolId,
        }),
        timeGroup() {
            const [currentYear, currentMonth, currentDate] = getDateInfo();
            let timer = getMacFormatDate(currentYear, currentMonth, currentDate)
            if (currentYear == this.year && currentMonth == this.month && currentDate == this.day) {
                return {
                    time: timer
                };
            } else {
                let t = [this.year, getFormatDay(this.month)].join('/')
                return {
                    time: t
                };
            }
        },
    },
    filters: {},
    created() {
        this.init();
    },
    methods: {
        /**
         * @Description: 初始化
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2024-07-08 14:50:03
         */
        async init() {
            this.initData();
            await this.getDaysStatus()
            this.getMonthsCalendarAndEvent()
        },
        /**
         * @Description: 初始化数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2024-07-08 14:50:15
         */
        initData() {
            this.query.schoolId = this.schoolId
            const [currentYear, currentMonth, currentDate] = getDateInfo();
            this.year = currentYear;
            this.month = currentMonth;
            this.day = currentDate;
            this.selectedDate = parseTime(new Date(), 'y-m-d')
            this.createDateNode(this.year, this.month)
        },
        /**
         * @Description: 获取校历数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2024-07-08 15:18:49
         */
        getDaysStatus() {
            this.mergedDays = this.days.map(day => ({...day}));
            this.getResultData();
        },
        getMonthsCalendarAndEvent() {
            this.query.startTime = this.mergedDays[0].fulDate
            this.query.endTime = this.mergedDays[this.mergedDays.length - 1].fulDate
            this._fet('/school/schoolCalendarEvent/getMonthsCalendarAndEvent', this.query).then((res) => {
                if (res.data.code === '200') {
                    this.calendarEvent = Object.assign({}, res.data.data)
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg)
                }
            })
        },
        /**
         * @Description: 处理校历数据分组
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2024-07-08 15:30:30
         */
        getResultData() {
            for (let i = 0; i < this.mergedDays.length; i += 7) {
                const chunk = this.mergedDays.slice(i, i + 7);
                this.chunkedDays.push(chunk);
            }
        },
        /**
         * @Description: 处理日期节点数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2024-07-08 15:29:44
         */
        createDateNode(year, month) {
            this.chunkedDays = [];
            const lastMonthRestDays = getLastMonthRestDays(year, month);
            const currentMonthDayCount = getMonthDayCount(year, month);
            const nextMonthRestDays = getNextMonthRestDays(year, month);

            this.days = [
                ...this.createLastDays(lastMonthRestDays, year, month),
                ...this.createCurrentDays(currentMonthDayCount, year, month),
                ...this.createNextDays(nextMonthRestDays, year, month)
            ];
        },
        /**
         * @Description: 获取当前月份的天数
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2024-07-08 15:28:26
         */
        createCurrentDays(currentMonthDayCount, year, month) {
            let tdArr = [];
            for (let i = 1; i <= currentMonthDayCount; i++) {
                /**
                 * status current 当月 last 上个月 next 下个月
                 * */
                let time = getFormatDate(year, month, i)
                let o = {
                    status: 'current',
                    date: getFormatDay(i),
                    fulDate: time,
                    currentDays: null
                }
                tdArr.push(o)
            }
            return tdArr;
        },
        /**
         * @Description: 获取上个月末尾的天数
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2024-07-08 15:28:36
         */
        createLastDays(lastMonthRestDays, year, month) {
            let tdArr = [];
            const {lastYear, lastMonth} = getLastYearMonth(year, month);

            lastMonthRestDays.map(item => {
                let time = getFormatDate(lastYear, lastMonth, item)
                let o = {
                    status: 'current',
                    date: getFormatDay(item),
                    fulDate: time,
                    noCurrent: true,
                    lastMon: true
                }
                tdArr.push(o)
            })

            return tdArr;
        },
        /**
         * @Description: 获取下个月开始的天数
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2024-07-08 15:26:20
         */
        createNextDays(nextMonthRestDays, year, month) {
            let tdArr = [];
            const {nextYear, nextMonth} = getNextYearMonth(year, month);

            nextMonthRestDays.map(item => {
                let time = getFormatDate(nextYear, nextMonth, item)
                let o = {
                    status: 'current',
                    date: getFormatDay(item),
                    fulDate: time,
                    noCurrent: true,
                    nextMon: true
                }
                tdArr.push(o)
            })

            return tdArr;
        },
        /**
         * @Description: 上个月点击事件
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2024-07-08 15:25:05
         */
        lastMonthHandlerClick: debounce(function () {
            const {lastYear, lastMonth} = getLastYearMonth(this.year, this.month);
            this.getNewList(lastYear, lastMonth)
            this.getMonthsCalendarAndEvent()
        }, 200),
        /**
         * @Description: 下个月点击事件
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2024-07-08 15:25:20
         */
        nextMonthHandlerClick: debounce(async function () {
            const {nextYear, nextMonth} = getNextYearMonth(this.year, this.month);
            this.getNewList(nextYear, nextMonth)
            this.getMonthsCalendarAndEvent()
        }, 200),
        /**
         * @Description: 获取新的校历数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2024-07-08 15:25:33
         */
        async getNewList(year, month) {
            this.year = year;
            this.month = month;
            this.day = undefined;
            this.createDateNode(this.year, this.month)
            await this.getDaysStatus()

            const [currentYear, currentMonth, currentDate] = getDateInfo();
            if (this.year == currentYear && this.month == currentMonth) {
                this.day = currentDate;
            }
        },
        /**
         * @Description: 今日点击事件
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2024-07-08 15:25:54
         */
        todayHandlerClick(data) {
            const [currentYear, currentMonth, currentDate] = getDateInfo();
            this.getNewList(currentYear, currentMonth)
            this.selectedDate = parseTime(new Date(), 'y-m-d')
            this.getMonthsCalendarAndEvent()
        },
        toggleExpand() {
            this.isExpanded = !this.isExpanded;
        },
        handleAllClick() {
            const { semesterId } = this.calendarEvent[this.selectedDate]
            if (semesterId) {
                this.$router.push({
                    name: 'TeachingResearchSchoolCalendarManagement',
                    query: {
                        id: semesterId,
                        type: 2
                    }
                })
            } else {
                this.$message.warning('当前时间未设置学期，无校历')
            }
        }
    },
};
</script>

<style scoped lang="scss">
.home-schoolCalendar {
    width: 462px;
    padding: 0;
}

.hm-title {
    padding: 10px 20px 9px;
    background: #F1F1F1;
}

.title-date {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    padding-left: 42px;

    .date {
        text-align: center;
        width: 113px;
        font-weight: bold;
        font-size: 14px;
        color: #333333;
    }

    .arrow-icon {
        transition: color .3s;
        cursor: pointer;
        color: #D1D1D1;
        font-weight: bold;

        &:hover {
            color: #2166EE;
        }
    }

    .clarification {
        cursor: pointer;
        margin-left: 14px;
        font-size: 14px;
        color: #2166EE;
    }
}

.title-right {
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 18px;

    span {
        color: #333333;
        font-size: 14px;
    }

    .el-icon-arrow-right {
        color: #A4A4A4;
        font-size: 14px;
        margin-left: 2px;
    }
}

.home-calendar {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
    margin: 13px 10px 0;

    .segmentation {
        flex-shrink: 0;
        cursor: pointer;

        .line {
            height: 1px;
            width: 100%;
            background: #E8E8E8;
        }

        .caret-icon {
            font-size: 16px;
            font-weight: bold;
            color: #E8E8E8;
            display: block;
            text-align: center;
            transition: all .3s;

            &:hover {
                color: #2166EE;
            }
        }
    }

    .event-wrap {
        flex: 1;
        overflow-y: auto;
        margin: 0 25px 8px 36px;

        .event-title {
            position: relative;
            font-size: 16px;
            padding-left: 10px;
            color: #333333;
            line-height: 30px;

            &::before {
                content: "";
                width: 6px;
                height: 6px;
                background: #3C7FFF;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
            }
        }
    }

    .empty-tips {
        text-align: center;
        font-size: 14px;
        color: #999999;
        margin-top: 20px;
    }
}
</style>
